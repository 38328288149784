<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-tabs pills>
          <b-tab v-for="group in dataInfo" :key="group.id">
            <template #title>
              <span>{{ group.name }}</span>
            </template>
            <b-row class="mt-1">
              <b-col cols="12" v-for="setting in group.settings" :key="setting.id">
                <b-form-group :label="setting.name" :label-for="setting.name">
                  <b-form-input v-model="setting.value" trim v-if="setting.type!==4"/>
                  <v-select v-model="setting.value" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="yesNoOptions" :reduce="val => val.value" :clearable="false" v-if="setting.type===4"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <action-buttons :back-route="'home'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store";
import storeModule from "@/views/system/settings/store";
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils"
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,

    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const toast = useToast()
    const busy = ref(true)
    const dataInfo = ref({})

    store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
      dataInfo.value = response.data.data
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'home'})
      }
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {
      if (!$themeConfig.app.appIsDemo) {
        busy.value = true
        store.dispatch('store/saveItem', dataInfo.value).then(response => {
          toastMessage(toast, 'success', response.data.message)
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        toastMessage(toast, 'danger', 'Demo kullanımda işlem yapılamaz!')
      }
    }

    const {
      refFormObserver,

      getValidationState,
      resetForm,
    } = formValidation()

    const yesNoOptions = [
      {label: 'Hayır', value: 'false'},
      {label: 'Evet', value: 'true'},
    ]

    return {
      busy,
      dataInfo,
      yesNoOptions,
      refFormObserver,

      onSubmit,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
